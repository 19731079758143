import React, { Fragment } from "react";

// components
import ReactPaginate from "react-paginate";
import { HiOutlineDotsHorizontal } from "react-icons/hi";

const Pagination = ({ handlePageClick, pageActive, pageCount, limit }) => {
  if (pageCount !== 0) {
    return (
      <Fragment>
        <div className="flex justify-center bg-white py-4 rounded-lg">
          <ReactPaginate
            className="flex justify-center text-base-800"
            activeLinkClassName="bg-primary-800 text-white font-semibold"
            pageLinkClassName="bg-gray-200 text-black font-semibold text-xs w-7 h-7 mx-1 flex rounded-lg justify-center items-center outline-none"
            previousLinkClassName="text-gray-400 text-xs w-fit h-7 mr-1 flex justify-center items-center"
            nextLinkClassName="text-gray-400 text-xs w-fit h-7 ml-1 flex justify-center items-center"
            breakLinkClassName="text-black bg-gray-200 font-semibold text-xs w-7 h-7 mx-1 flex rounded-lg justify-center items-end"
            disabledLinkClassName="text-base-500"
            breakLabel={<HiOutlineDotsHorizontal />}
            renderOnZeroPageCount={null}
            previousLabel={"Previous"}
            nextLabel={"Next"}
            pageCount={Math.ceil(pageCount / limit)}
            onPageChange={(e) => handlePageClick(e)}
            marginPagesDisplayed={1}
            pageRangeDisplayed={2}
            forcePage={pageActive}
          />
        </div>
      </Fragment>
    );
  }
  return null;
};

export default Pagination;
