import { createSlice } from "@reduxjs/toolkit";

export const adminReducers = createSlice({
  name: "admin",
  initialState: {
    addDataResult: false,
    addDataLoading: false,
    getDataResult: false,
    getDataLoading: false,
    getDataError: false,
    deleteDataResult: false,

    addBroadcastResult: false,
    addBroadcastLoading: false,
  },
  reducers: {
    dataReducer: (state, action) => {
      const { type, payload } = action.payload;
      switch (type) {
        case "ADD_BROADCAST":
          return {
            ...state,
            addBroadcastResult: payload.data,
            addBroadcastLoading: payload.loading,
          };
        case "GET_DATA":
          return {
            ...state,
            getDataResult: payload.data,
            getDataLoading: payload.loading,
            getDataError: payload.error,
          };
        case "ADD_DATA":
          return {
            ...state,
            addDataResult: payload.data,
            addDataLoading: payload.loading,
          };
        case "DELETE_DATA":
          return {
            ...state,
            deleteDataResult: payload.data,
          };
        default:
          return state;
      }
    },
  },
});

export const { dataReducer } = adminReducers.actions;

export default adminReducers.reducer;
