import React from "react";

const ButtonHover = ({
  children,
  textHover,
  onClick,
  placement = "middle",
  disabled,
}) => {
  return (
    <div className="relative cursor-pointer group/tooltip">
      <button
        type="button"
        disabled={disabled}
        onClick={onClick}
        className={`flex
				${
          onClick
            ? "focus:outline-none focus:ring-2 focus:ring-base-600/50 focus:bg-slate-600/50 rounded"
            : "outline-none"
        }`}
      >
        {children}
      </button>
      <div
        className={`absolute -top-9 hidden w-max group-hover/tooltip:block pointer-events-none bg-white dark:bg-neutral-700 text-base-800 text-black shadow rounded text-xs px-3 py-1 transition-all bg-opacity-90 select-none
				${placement === "left" ? "left-0 text-left" : ""}
				${placement === "right" ? "right-0 text-right" : ""}
				${placement === "middle" ? "left-1/2 transform -translate-x-1/2" : ""}
				`}
      >
        {textHover}
      </div>
    </div>
  );
};

export default ButtonHover;
