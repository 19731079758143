import React from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

const Button = ({
  color = "primary",
  type = "button",
  size = "sm",
  disabled,
  onClick,
  loading,
  children,
}) => {
  if (color === "success") {
    return (
      <button
        className={`bg-gradient-to-r from-green-900 to-green-600 hover:from-green-800 hover:to-green-400 text-white font-semibold p-2 px-2 flex justify-center items-center text-${size} rounded-md disabled:cursor-not-allowed`}
        type={type}
        disabled={disabled || loading}
        onClick={onClick}
      >
        {loading ? (
          <>
            <AiOutlineLoading3Quarters className="animate-spin mr-2 cursor-wait" />
            Loading...
          </>
        ) : (
          children
        )}
      </button>
    );
  } else if (color === "secondary") {
    return (
      <button
        className={`bg-gradient-to-r from-secondary-900 to-secondary-700 hover:from-secondary-800 hover:to-secondary-400 text-white font-semibold p-2 px-4 flex justify-center items-center text-${size} rounded-md disabled:cursor-not-allowed`}
        type={type}
        disabled={disabled || loading}
        onClick={onClick}
      >
        {loading ? (
          <>
            <AiOutlineLoading3Quarters className="animate-spin mr-2 cursor-wait" />
            Loading...
          </>
        ) : (
          children
        )}
      </button>
    );
  } else if (color === "primary") {
    return (
      <button
        className={`bg-gradient-to-r from-primary-900 to-primary-500 hover:from-primary-800 hover:to-primary-400 text-white font-semibold p-2 px-4 flex justify-center items-center text-${size} rounded-md disabled:cursor-not-allowed`}
        type={type}
        disabled={disabled || loading}
        onClick={onClick}
      >
        {loading ? (
          <>
            <AiOutlineLoading3Quarters className="animate-spin mr-2 cursor-wait" />
            Loading...
          </>
        ) : (
          children
        )}
      </button>
    );
  }
};

export default Button;
