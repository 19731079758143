import React from "react";
import NoData from "assets/images/NoData.png";
import ErrorImage from "assets/images/Error.png";

const ErrorMessages = ({ type, messages }) => {
  if (type === "error")
    return (
      <div>
        <img className="w-40" src={ErrorImage} alt="nodata" />
        <p className="text-[#d9d9d9] dark:text-white text-sm text-center">
          {messages}
        </p>
      </div>
    );

  if (type === "nodata")
    return (
      <div>
        <img className="w-40" src={NoData} alt="nodata" />
        <p className="text-[#d9d9d9] font-semibold mt-2 dark:text-white text-sm text-center">
          {messages}
        </p>
      </div>
    );

  return null;
};

export default ErrorMessages;
