import React from "react";
import {
  HiEye,
  HiEyeOff,
  HiOutlinePlusCircle,
  HiOutlineXCircle,
} from "react-icons/hi";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import Datepicker from "react-tailwindcss-datepicker";

const Input = ({
  type,
  formik,
  id,
  name,
  placeholder,
  options,
  onChange,
  isMulti,
  isSearchable,
  disabled,
  createTable,
  showPassword,
  setShowPassword,
  accept,
  rows,
  multiple,
  preview,
  text,
}) => {
  const validNumber = new RegExp(/^\d*\.?\d*$/);

  // multiple
  if (multiple) {
    const index = name.split("_").pop();
    const idClass = id.replace("_" + index, "");
    const nameClass = name.replace("_" + index, "");
    if (["text", "email", "date", "time"].includes(type)) {
      return (
        <>
          <input
            className={`disabled:bg-gray-200 bg-white p-2 text-sm border rounded-sm w-full outline-none dark:bg-neutral-700  ${
              formik.touched[nameClass] &&
              formik.errors[nameClass] &&
              formik.touched[nameClass][index][idClass] &&
              formik.errors[nameClass][index][idClass]
                ? "border-red-500 dark:border-red-700"
                : "dark:border-neutral-600 "
            }
            `}
            id={id}
            name={name}
            placeholder={placeholder}
            type={type}
            onChange={onChange}
            value={formik.values[nameClass][index][idClass] || ""}
            disabled={disabled}
            accept={accept}
          />
          {formik.touched[nameClass] &&
          formik.errors[nameClass] &&
          formik.touched[nameClass][index][idClass] &&
          formik.errors[nameClass][index][idClass] ? (
            <div className="text-red-500 dark:border-red-700 text-xs">
              {formik.errors[nameClass][index][idClass]}
            </div>
          ) : null}
        </>
      );
    } else if (type === "select") {
      return (
        <>
          <select
            className={`disabled:bg-gray-200 p-2 text-sm border rounded-sm w-full bg-white outline-none dark:bg-neutral-700 ${
              formik.touched[nameClass]?.[index]?.[idClass] &&
              formik.errors[nameClass]?.[index]?.[idClass]
                ? "border-red-500 dark:border-red-700"
                : "dark:border-neutral-600"
            }`}
            id={id}
            name={name}
            placeholder={placeholder}
            onChange={onChange}
            value={formik.values[nameClass][index][idClass]}
            disabled={disabled}
          >
            <option value="" disabled>
              Pilih
            </option>
            {options.map((item, index) => (
              <option value={item.value} key={index}>
                {item.label}
              </option>
            ))}
          </select>
          {formik.touched[nameClass]?.[index]?.[idClass] &&
          formik.errors[nameClass]?.[index]?.[idClass] ? (
            <div className="text-red-500 dark:border-red-700 text-xs">
              {formik.errors[nameClass]?.[index]?.[idClass]}
            </div>
          ) : null}
        </>
      );
    } else if (type === "textarea") {
      return (
        <>
          <textarea
            className={`disabled:bg-gray-200 bg-white p-2 text-sm border rounded-sm w-full outline-none dark:bg-neutral-700 ${
              formik.touched[nameClass]?.[index]?.[idClass] &&
              formik.errors[nameClass]?.[index]?.[idClass]
                ? "border-red-500 dark:border-red-700"
                : "dark:border-neutral-600"
            }`}
            id={id}
            name={name}
            placeholder={placeholder}
            onChange={onChange}
            value={formik.values[nameClass][index][idClass] || ""}
            disabled={disabled}
            rows={rows}
          />
          {formik.touched[nameClass]?.[index]?.[idClass] &&
          formik.errors[nameClass]?.[index]?.[idClass] ? (
            <div className="text-red-500 dark:border-red-700 text-xs">
              {formik.errors[nameClass][index][idClass]}
            </div>
          ) : null}
        </>
      );
    } else if (type === "number") {
      return (
        <>
          <input
            className={`disabled:bg-gray-200 bg-white p-2 text-sm border rounded-sm w-full outline-none dark:bg-neutral-700  ${
              formik.touched[nameClass]?.[index]?.[idClass] &&
              formik.errors[nameClass]?.[index]?.[idClass]
                ? "border-red-500 dark:border-red-700"
                : "dark:border-neutral-600 "
            }
            `}
            id={id}
            name={name}
            placeholder={placeholder}
            type={"text"}
            onChange={(e) => {
              if (validNumber.test(e.target.value)) {
                onChange(e);
              }
            }}
            value={formik.values[nameClass][index][idClass] || ""}
            disabled={disabled}
            accept={accept}
          />
          {formik.touched[nameClass]?.[index]?.[idClass] &&
          formik.errors[nameClass]?.[index]?.[idClass] ? (
            <div className="text-red-500 dark:border-red-700 text-xs">
              {formik.errors[nameClass][index][idClass]}
            </div>
          ) : null}
        </>
      );
    } else if (type === "color") {
      return (
        <>
          <input
            className={`disabled:bg-gray-200 bg-white h-[38px] text-sm border rounded-sm w-full outline-none dark:bg-neutral-700  ${
              formik.touched[nameClass] &&
              formik.errors[nameClass] &&
              formik.touched[nameClass][index][idClass] &&
              formik.errors[nameClass][index][idClass]
                ? "border-red-500 dark:border-red-700"
                : "dark:border-neutral-600 "
            }
            `}
            id={id}
            name={name}
            placeholder={placeholder}
            type={type}
            onChange={onChange}
            value={formik.values[nameClass][index][idClass] || ""}
            disabled={disabled}
            accept={accept}
          />
          {formik.touched[nameClass] &&
          formik.errors[nameClass] &&
          formik.touched[nameClass][index][idClass] &&
          formik.errors[nameClass][index][idClass] ? (
            <div className="text-red-500 dark:border-red-700 text-xs">
              {formik.errors[nameClass][index][idClass]}
            </div>
          ) : null}
        </>
      );
    }
  }

  // single
  if (
    ["text", "email", "date", "time", "file", "datetime-local"].includes(type)
  ) {
    return (
      <>
        <input
          className={`disabled:bg-gray-200 bg-white p-2 text-sm border rounded-sm w-full outline-none dark:bg-neutral-700  ${
            formik.touched[id] && formik.errors[id]
              ? "border-red-500 dark:border-red-700"
              : "dark:border-neutral-600 "
          }`}
          id={id}
          name={name}
          placeholder={placeholder}
          type={type}
          onChange={onChange}
          value={
            type !== "file"
              ? formik.values[id]
              : formik.values[id].length > 0
              ? formik.values[id][0].fileName
              : ""
          }
          disabled={disabled}
          accept={accept}
        />

        {type === "file" && formik.values[id].length > 0 && (
          <div className="text-xs">{formik.values[id][0].name}</div>
        )}
        {formik.touched[id] && formik.errors[id] ? (
          <div className="text-red-500 dark:border-red-700 text-xs">
            {formik.errors[id]}
          </div>
        ) : null}
      </>
    );
  } else if (type === "textarea") {
    return (
      <>
        <textarea
          className={`disabled:bg-gray-200 bg-white p-2 text-sm border rounded-sm w-full outline-none dark:bg-neutral-700 ${
            formik.touched[id] && formik.errors[id]
              ? "border-red-500 dark:border-red-700"
              : "dark:border-neutral-600"
          }`}
          id={id}
          name={name}
          placeholder={placeholder}
          onChange={onChange}
          value={formik.values[id]}
          disabled={disabled}
          rows={rows}
        />
        {formik.touched[id] && formik.errors[id] ? (
          <div className="text-red-500 dark:border-red-700 text-xs">
            {formik.errors[id]}
          </div>
        ) : null}
      </>
    );
  } else if (type === "select") {
    return (
      <>
        <select
          className={`disabled:bg-gray-200 p-2 text-sm border rounded-sm w-full bg-white outline-none dark:bg-neutral-700 ${
            formik.touched[id] && formik.errors[id]
              ? "border-red-500 dark:border-red-700"
              : "dark:border-neutral-600"
          }`}
          id={id}
          name={name}
          placeholder={placeholder}
          onChange={onChange}
          value={formik.values[id]}
          disabled={disabled}
        >
          <option value="" disabled>
            Pilih
          </option>
          {options.map((item, index) => (
            <option value={item.value} key={index}>
              {item.label}
            </option>
          ))}
        </select>
        {formik.touched[id] && formik.errors[id] ? (
          <div className="text-red-500 dark:border-red-700 text-xs">
            {formik.errors[id]}
          </div>
        ) : null}
      </>
    );
  } else if (type === "Select") {
    if (createTable) {
      return (
        <>
          <CreatableSelect
            className={`${
              formik.touched[id] && formik.errors[id]
                ? "my-react-select-container-error"
                : "my-react-select-container"
            }`}
            classNamePrefix={`${
              formik.touched[id] && formik.errors[id]
                ? "my-react-select-error"
                : "my-react-select"
            }`}
            isClearable
            isSearchable={isSearchable}
            isMulti={isMulti}
            isDisabled={disabled}
            id={id}
            name={name}
            options={options.map((item) => ({
              value: item.value,
              label: item.label,
            }))}
            value={formik.values[id]}
            onChange={onChange}
          />
          {formik.touched[id] && formik.errors[id] ? (
            <div className="text-red-500 dark:border-red-700 text-xs">
              {formik.errors[id]}
            </div>
          ) : null}
        </>
      );
    }
    return (
      <>
        <Select
          className={`${
            formik.touched[id] && formik.errors[id]
              ? "my-react-select-container-error"
              : "my-react-select-container"
          }`}
          classNamePrefix={`${
            formik.touched[id] && formik.errors[id]
              ? "my-react-select-error"
              : "my-react-select"
          }`}
          isSearchable={isSearchable}
          isMulti={isMulti}
          isDisabled={disabled}
          id={id}
          name={name}
          options={options.map((item) => ({
            value: item.value,
            label: item.label,
          }))}
          value={formik.values[id]}
          onChange={onChange}
        />
        {formik.touched[id] && formik.errors[id] ? (
          <div className="text-red-500 dark:border-red-700 text-xs">
            {formik.errors[id]}
          </div>
        ) : null}
      </>
    );
  } else if (type === "password") {
    return (
      <>
        <div className="relative ">
          <input
            className={`disabled:bg-gray-200 bg-white p-2 text-sm border rounded-sm w-full outline-none dark:bg-neutral-700  ${
              formik.touched[id] && formik.errors[id]
                ? "border-red-500 dark:border-red-700"
                : "dark:border-neutral-600 "
            }`}
            id={id}
            name={name}
            placeholder={placeholder}
            onChange={onChange}
            value={formik.values[id]}
            disabled={disabled}
            type={showPassword ? "text" : "password"}
          />
          {/* Show Password */}
          <div className="absolute top-2.5 right-3 text-lg text-gray-600 dark:text-white cursor-pointer">
            {showPassword ? (
              <HiEyeOff onClick={() => setShowPassword(false)} />
            ) : (
              <HiEye onClick={() => setShowPassword(true)} />
            )}
          </div>
        </div>
        {formik.touched[id] && formik.errors[id] ? (
          <div className="text-red-500 dark:border-red-700 text-xs">
            {formik.errors[id]}
          </div>
        ) : null}
      </>
    );
  } else if (type === "image") {
    return (
      <>
        <input
          className={`disabled:bg-gray-200 bg-white p-2 text-sm border rounded-sm w-full outline-none dark:bg-neutral-700  ${
            formik.touched[id] && formik.errors[id]
              ? "border-red-500 dark:border-red-700"
              : "dark:border-neutral-600 "
          }`}
          id={id}
          name={name}
          type="file"
          onChange={onChange}
          disabled={disabled}
          accept={"image/*"}
        />
        {formik.touched[id] && formik.errors[id] ? (
          <div className="text-red-500 dark:border-red-700 text-xs">
            {formik.errors[id]}
          </div>
        ) : null}

        {preview &&
          (formik.values[id].length > 0 ? (
            <img
              className="object-cover w-full mt-3"
              src={formik.values[id][0]["preview"]}
              alt="profile"
            />
          ) : (
            <div className="border-2 border-dashed w-full py-10 mt-3 text-center">
              Preview
            </div>
          ))}
      </>
    );
  } else if (type === "image-multi") {
    return (
      <>
        <input
          className={`disabled:bg-gray-200 bg-white p-2 text-sm border rounded-sm w-full outline-none dark:bg-neutral-700  ${
            formik.touched[id] && formik.errors[id]
              ? "border-red-500 dark:border-red-700"
              : "dark:border-neutral-600 "
          }`}
          id={id}
          name={name}
          type="file"
          onChange={onChange}
          disabled={disabled}
          accept={"image/*"}
          multiple
        />
        {formik.touched[id] && formik.errors[id] ? (
          <div className="text-red-500 dark:border-red-700 text-xs">
            {formik.errors[id]}
          </div>
        ) : null}

        {preview &&
          (formik.values[id].length > 0 ? (
            <div className="flex flex-wrap gap-2 mt-2">
              {formik.values[id].map((item, index) => (
                <div
                  key={index}
                  className="group/image flex relative h-28 w-28 bg-gray-100 justify-center rounded-lg p-1 hover:border "
                >
                  <img
                    className="object-contain"
                    src={item["preview"]}
                    alt="profile"
                  />
                  <HiOutlineXCircle
                    onClick={() => {
                      const data = [...formik.values[id]];
                      data.splice(index, 1);
                      formik.setFieldValue([id], data);
                    }}
                    size={21}
                    className="text-red-500 absolute top-0 right-0 -mt-2 -mr-2 hover:cursor-pointer invisible group-hover/image:visible"
                  />
                </div>
              ))}
              <div className="flex relative h-28 w-28 bg-gray-100 justify-center rounded-lg p-1 hover:border ">
                <div className="flex justify-center items-center">
                  <HiOutlinePlusCircle size={32} />
                </div>
                <input
                  onChange={(e) => {
                    const files = [...formik.values[id]];
                    Array.from(e.target.files).forEach((item) => {
                      const file = item;
                      file["preview"] = URL.createObjectURL(item);
                      files.push(file);
                    });
                    formik.setFieldValue([id], files);
                  }}
                  className="absolute h-full w-full opacity-0 cursor-pointer"
                  type="file"
                  accept="image/*"
                  multiple
                />
              </div>
            </div>
          ) : (
            <div className="border-2 border-dashed w-full py-10 mt-3 text-center">
              Preview
            </div>
          ))}
      </>
    );
  } else if (type === "date-range") {
    return (
      <>
        <Datepicker
          toggleClassName={`absolute bg-gray-300 rounded-r-sm text-black right-0 h-full px-3 focus:outline-none disabled:opacity-40 disabled:cursor-not-allowed ${
            formik.touched[id] && formik.errors[id]
              ? "border border-red-500 dark:border-red-700"
              : "dark:border-neutral-600 "
          }`}
          popoverDirection="down"
          inputClassName={`w-full border rounded outline-none px-4 py-2 text-sm border-neutral-300 dark:border-neutral-700 dark:bg-neutral-800 dark:text-neutral ${
            formik.touched[id] && formik.errors[id]
              ? "border-red-500 dark:border-red-700"
              : "dark:border-neutral-600 "
          }`}
          useRange={false}
          primaryColor="indigo"
          value={formik.values[id]}
          onChange={onChange}
          disabled={disabled}
          displayFormat={"DD/MM/YYYY"}
        />
        {formik.touched[id] && formik.errors[id] ? (
          <div className="text-red-500 dark:border-red-700 text-xs">
            {formik.errors[id]["startDate"]}
          </div>
        ) : null}
      </>
    );
  } else if (type === "number-telp") {
    return (
      <>
        <div className="flex flex-row">
          <div
            className={`flex bg-gray-300 px-2 items-center rounded-l-sm ${
              formik.touched[id] && formik.errors[id]
                ? "border-red-500 dark:border-red-700 border"
                : "dark:border-neutral-600"
            }`}
          >
            {"+62"}
          </div>
          <input
            className={`disabled:bg-gray-200 bg-white p-2 text-sm border-y border-r rounded-r-sm w-full outline-none dark:bg-neutral-700 ${
              formik.touched[id] && formik.errors[id]
                ? "border-red-500 dark:border-red-700"
                : "dark:border-neutral-600"
            }`}
            id={id}
            name={name}
            type="text"
            placeholder={placeholder}
            onChange={(e) => {
              if (validNumber.test(e.target.value)) {
                onChange(e);
              }
            }}
            value={formik.values[id]}
            disabled={disabled}
          />
        </div>
        {formik.touched[id] && formik.errors[id] ? (
          <div className="text-red-500 dark:border-red-700 text-xs">
            {formik.errors[id]}
          </div>
        ) : null}
      </>
    );
  } else if (type === "checkbox-toogle") {
    return (
      <>
        <div className="flex flex-row">
          <div>
            <label className="relative inline-flex items-center cursor-pointer">
              <input
                id={id}
                name={name}
                onChange={onChange}
                checked={formik.values[id]}
                type="checkbox"
                className="peer absolute z-10 sr-only cursor-pointer"
              />
              <div className="w-7 h-4 bg-gray-200 rounded-full peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-3 after:w-3 after:transition-all peer-checked:bg-primary-600"></div>
            </label>
          </div>
          <span className="text-sm justify-center items-center text-gray-500 ml-2 transition-all">
            {formik.values[id] ? "Aktif" : "Nonaktif"}
          </span>
        </div>
        {formik.touched[id] && formik.errors[id] ? (
          <div className="text-red-500 dark:border-red-700 text-xs">
            {formik.errors[id]}
          </div>
        ) : null}
      </>
    );
  } else if (type === "groups-number") {
    return (
      <>
        <div className="flex flex-row">
          <input
            className={`disabled:bg-gray-200 bg-white p-2 text-sm border-y border-l rounded-l-sm w-full outline-none dark:bg-neutral-700 ${
              formik.touched[id] && formik.errors[id]
                ? "border-red-500 dark:border-red-700"
                : "dark:border-neutral-600"
            }`}
            id={id}
            name={name}
            type={"text"}
            placeholder={placeholder}
            onChange={(e) => {
              if (validNumber.test(e.target.value)) {
                onChange(e);
              }
            }}
            value={formik.values[id]}
            disabled={disabled}
          />
          <div
            className={`flex bg-gray-300 px-2 items-center rounded-r-sm  ${
              formik.touched[id] && formik.errors[id]
                ? "border-red-500 dark:border-red-700 border"
                : "dark:border-neutral-600"
            }`}
          >
            {text}
          </div>
        </div>

        {formik.touched[id] && formik.errors[id] ? (
          <div className="text-red-500 dark:border-red-700 text-xs">
            {formik.errors[id]}
          </div>
        ) : null}
      </>
    );
  } else if (type === "number") {
    return (
      <>
        <input
          className={`disabled:bg-gray-200 bg-white p-2 text-sm border rounded-sm w-full outline-none dark:bg-neutral-700  ${
            formik.touched[id] && formik.errors[id]
              ? "border-red-500 dark:border-red-700"
              : "dark:border-neutral-600 "
          }`}
          id={id}
          name={name}
          placeholder={placeholder}
          type={"text"}
          onChange={(e) => {
            if (validNumber.test(e.target.value)) {
              onChange(e);
            }
          }}
          value={formik.values[id]}
          disabled={disabled}
          accept={accept}
        />
        {formik.touched[id] && formik.errors[id] ? (
          <div className="text-red-500 dark:border-red-700 text-xs">
            {formik.errors[id]}
          </div>
        ) : null}
      </>
    );
  } else if (type === "color") {
    return (
      <>
        <input
          className={`disabled:bg-gray-200 bg-white h-[38px] text-sm border rounded-sm w-full outline-none dark:bg-neutral-700  ${
            formik.touched[id] && formik.errors[id]
              ? "border-red-500 dark:border-red-700"
              : "dark:border-neutral-600 "
          }`}
          id={id}
          name={name}
          placeholder={placeholder}
          type={type}
          onChange={onChange}
          value={
            type !== "file"
              ? formik.values[id]
              : formik.values[id].length > 0
              ? formik.values[id][0].fileName
              : ""
          }
          disabled={disabled}
          accept={accept}
        />

        {type === "file" && formik.values[id].length > 0 && (
          <div className="text-xs">{formik.values[id][0].name}</div>
        )}
        {formik.touched[id] && formik.errors[id] ? (
          <div className="text-red-500 dark:border-red-700 text-xs">
            {formik.errors[id]}
          </div>
        ) : null}
      </>
    );
  }

  return null;
};

export default Input;
