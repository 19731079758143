import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import reducers from "reducers";
import { GoogleOAuthProvider } from "@react-oauth/google";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={reducers}>
    <GoogleOAuthProvider clientId="732326693590-nmgp6ca0hjeg2l7849ncgum405tsu4u2.apps.googleusercontent.com">
      <App />
    </GoogleOAuthProvider>
  </Provider>
);
