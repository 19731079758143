// plugins
import axios from "axios";
import Swal from "sweetalert2";

export function resetRedux(reducers, type) {
  const { dispatch, redux } = reducers;
  dispatch(
    redux({
      type: type,
      payload: {
        data: false,
        loading: false,
      },
    })
  );
}

export function errorMessage(error) {
  if (error.response && error.response.data && error.response.data.messages) {
    return error.response.data.messages;
  } else if (error.response && error.response.statusText) {
    return error.response.statusText;
  }
  return error.message;
}

export const getData = (reducers, param, url, type) => {
  const { dispatch, redux } = reducers;

  dispatch(
    redux({
      type: type,
      payload: {
        loading: true,
        data: false,
        error: false,
      },
    })
  );
  axios({
    method: "GET",
    url: url,
    params: param,
  })
    .then((response) => {
      dispatch(
        redux({
          type: type,
          payload: {
            loading: false,
            data: response.data,
            error: false,
          },
        })
      );
    })
    .catch((error) => {
      dispatch(
        redux({
          type: type,
          payload: {
            loading: false,
            data: false,
            error: errorMessage(error),
          },
        })
      );
    });
};

export const addFormData = (reducers, data, url, type) => {
  const { dispatch, redux } = reducers;

  dispatch(
    redux({
      type: type,
      payload: {
        loading: true,
        data: false,
      },
    })
  );
  axios({
    method: "POST",
    url: url,
    data: data,
    headers: { "Content-Type": "multipart/form-data" },
  })
    .then((response) => {
      if (response.data.status === 201) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          customClass: {
            container: "z-[99999]",
          },
          text: response.data.messages,
        });
      } else {
        Swal.fire({
          icon: "success",
          title: "Good job!",
          customClass: {
            container: "z-[99999]",
          },
          text: response.data.messages,
          showConfirmButton: false,
          timer: 1500,
        });
      }
      dispatch(
        redux({
          type: type,
          payload: {
            loading: false,
            data: response.data,
          },
        })
      );
    })
    .catch((error) => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        customClass: {
          container: "z-[99999]",
        },
        text: errorMessage(error),
      });
      dispatch(
        redux({
          type: type,
          payload: {
            loading: false,
            data: false,
          },
        })
      );
    });
};
export const addData = (reducers, data, url, type) => {
  const { dispatch, redux } = reducers;

  dispatch(
    redux({
      type: type,
      payload: {
        loading: true,
        data: false,
      },
    })
  );

  axios({
    method: "POST",
    url: url,
    data: data,
  })
    .then((response) => {
      if (response.data.status === 201) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          customClass: {
            container: "z-[99999]",
          },
          text: response.data.messages,
        });
      } else {
        Swal.fire({
          icon: "success",
          title: "Good job!",
          customClass: {
            container: "z-[99999]",
          },
          text: response.data.messages,
          showConfirmButton: false,
          timer: 1500,
        });
      }
      dispatch(
        redux({
          type: type,
          payload: {
            loading: false,
            data: response.data,
          },
        })
      );
    })
    .catch((error) => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        customClass: {
          container: "z-[99999]",
        },
        text: errorMessage(error),
      });
      dispatch(
        redux({
          type: type,
          payload: {
            loading: false,
            data: false,
          },
        })
      );
    });
};

export const updateData = (reducers, data, url, type) => {
  const { dispatch, redux } = reducers;

  dispatch(
    redux({
      type: type,
      payload: {
        loading: true,
        data: false,
      },
    })
  );

  axios({
    method: "PUT",
    url: url + data.pk,
    data: data,
  })
    .then((response) => {
      if (response.data.status === 201) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          customClass: {
            container: "z-[99999]",
          },
          text: response.data.messages,
        });
      } else {
        Swal.fire({
          icon: "success",
          title: "Good job!",
          customClass: {
            container: "z-[99999]",
          },
          text: response.data.messages,
          showConfirmButton: false,
          timer: 1500,
        });
      }
      dispatch(
        redux({
          type: type,
          payload: {
            loading: false,
            data: response.data,
          },
        })
      );
    })
    .catch((error) => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        customClass: {
          container: "z-[99999]",
        },
        text: errorMessage(error),
      });
      dispatch(
        redux({
          type: type,
          payload: {
            loading: false,
            data: false,
          },
        })
      );
    });
};

export const deleteData = (reducers, pk, url, type) => {
  const { dispatch, redux } = reducers;
  dispatch(
    redux({
      type: type,
      payload: {
        data: false,
      },
    })
  );
  Swal.fire({
    title: "Anda Yakin?",
    text: "Anda tidak akan dapat mengembalikan data ini!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#0369a1",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, delete it!",
    customClass: {
      container: "z-[99999]",
    },
  }).then((result) => {
    if (result.isConfirmed) {
      axios({
        method: "DELETE",
        url: url + pk,
      })
        .then((response) => {
          Swal.fire({
            icon: "success",
            title: "Good job!",
            customClass: {
              container: "z-[99999]",
            },
            text: response.data.messages,
            showConfirmButton: false,
            timer: 1500,
          });
          dispatch(
            redux({
              type: type,
              payload: {
                data: response.data,
              },
            })
          );
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            customClass: {
              container: "z-[99999]",
            },
            text: errorMessage(error),
          });
          dispatch(
            redux({
              type: type,
              payload: {
                data: false,
              },
            })
          );
        });
    }
  });
};
