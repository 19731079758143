import {
  addData,
  addFormData,
  deleteData,
  getData,
  resetRedux,
  updateData,
} from "actions";
import axios from "axios";
import {
  Button,
  ButtonHover,
  ErrorMessages,
  HeadContent,
  Input,
  Label,
  Modal,
  Pagination,
} from "components";
import {
  API_URL_broadcast,
  API_URL_createkontak,
  API_URL_createsettings,
  API_URL_deleteallkontak,
  API_URL_deleteimage,
  API_URL_edelkontak,
  API_URL_getsettings,
  API_URL_importkontak,
  API_URL_kontaks,
} from "constants";
import { useFormik } from "formik";
import { useCallback, useEffect, useState } from "react";
import { FiEdit } from "react-icons/fi";
import { HiOutlineXCircle } from "react-icons/hi";
import { RiDeleteBin6Line } from "react-icons/ri";
import ReactLoading from "react-loading";
import { useDispatch, useSelector } from "react-redux";
import { dataReducer } from "reducers/adminReducers";
import Swal from "sweetalert2";
import * as Yup from "yup";

const FirstPage = () => {
  const {
    addBroadcastResult,
    addBroadcastLoading,
    addDataResult,
    addDataLoading,
    getDataResult,
    getDataLoading,
    getDataError,
    deleteDataResult,
  } = useSelector((state) => state.admin);
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      warding: "",
      image: [],
    },
    validationSchema: Yup.object({
      warding: Yup.string().required("Warding wa harus diisi"),
    }),
    onSubmit: (values, helpers) => {
      const formData = new FormData();
      formData.append("warding", values.warding);
      formData.append("image", values.image.length > 0 ? values.image[0] : "");
      addFormData(
        { dispatch, redux: dataReducer },
        formData,
        API_URL_createsettings,
        "ADD_DATA"
      );
    },
  });

  const formikKontak = useFormik({
    initialValues: {
      pk: "",
      nohp: "",
      nama: "",
      perusahaan: "",
      panggilan: "",
    },
    validationSchema: Yup.object({
      nohp: Yup.string().required("No hp direktur/owner harus diisi"),
      nama: Yup.string().required("Nama direktur/owner harus diisi"),
      perusahaan: Yup.string().required("Nama perusahaan harus diisi"),
      panggilan: Yup.string().required("Panggilan harus diisi"),
    }),
    onSubmit: (values) => {
      if (values.pk) {
        updateData(
          { dispatch, redux: dataReducer },
          { ...values },
          API_URL_edelkontak,
          "ADD_DATA"
        );
      } else {
        addData(
          { dispatch, redux: dataReducer },
          { ...values },
          API_URL_createkontak,
          "ADD_DATA"
        );
      }
    },
  });

  const [limit, setLimit] = useState(10);
  const [pageActive, setPageActive] = useState(0);
  const [search, setSearch] = useState("");
  const [warding, setWarding] = useState("");
  const [image, setImage] = useState("");

  const [modalWarding, setModalWarding] = useState(false);
  const [modalKontak, setModalKontak] = useState(false);

  const get = useCallback(
    async (param) => {
      getData(
        { dispatch, redux: dataReducer },
        param,
        API_URL_kontaks,
        "GET_DATA"
      );
    },
    [dispatch]
  );

  const handlePageClick = (e) => {
    const offset = e.selected * limit;
    const param = {};
    if (search !== "") {
      param.search = search;
    }
    param.limit = limit;
    param.offset = offset;
    get(param);
    setPageActive(e.selected);
  };

  const handleSelect = (e) => {
    const param = {};
    if (search !== "") {
      param.search = search;
    }
    param.limit = e;
    get(param);
    setLimit(e);
    setPageActive(0);
  };

  const onSearch = (e) => {
    const { value } = e.target;
    setSearch(value);
    setLimit(10);
    setPageActive(0);
    get({ search: value });
  };

  const onDelete = (item) => {
    deleteData(
      { dispatch, redux: dataReducer },
      item.id,
      API_URL_edelkontak,
      "DELETE_DATA"
    );
  };

  const onBroadcast = () => {
    Swal.fire({
      title: "Anda Yakin?",
      text: "Anda yakin ingin broadcast pesan?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0369a1",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, broadcast!",
      customClass: {
        container: "z-[99999]",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        addData(
          { dispatch, redux: dataReducer },
          {},
          API_URL_broadcast,
          "ADD_BROADCAST"
        );
      }
    });
  };

  const onEdit = (item) => {
    formikKontak.setValues({
      nama: item.nama,
      nohp: item.nohp,
      perusahaan: item.perusahaan,
      panggilan: item.panggilan,
      pk: item.id,
    });
    setModalKontak(true);
  };

  const handleImport = (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("import_file", file);
    addFormData(
      { dispatch, redux: dataReducer },
      formData,
      API_URL_importkontak,
      "ADD_DATA"
    );
  };
  const onDeleteAll = () => {
    addData(
      { dispatch, redux: dataReducer },
      {},
      API_URL_deleteallkontak,
      "ADD_DATA"
    );
  };

  const fetchData = useCallback(
    async (param = false) => {
      setModalWarding(false);
      setModalKontak(false);
      get(param ? param : { limit: limit });

      const res = await axios.get(API_URL_getsettings);
      setWarding(res.data.warding);
      setImage(res.data.image);
    },
    [get] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (addDataResult || deleteDataResult) {
      const offset = pageActive * limit;
      const param = {};
      if (search !== "") {
        param.search = search;
      }
      param.limit = limit;
      param.offset = offset;
      fetchData(param);
      resetRedux({ dispatch, redux: dataReducer }, "ADD_DATA");
    }
  }, [addDataResult, deleteDataResult, dispatch]); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <div className="bg-white">
      <div className="flex flex-col bg-base p-10">
        <div className="border border-primary-700 border-b-4 rounded-lg p-5">
          <div className="flex flex-col w-full">
            <div className="font-bold text-lg">Pesan</div>
            {image && (
              <div className="flex my-3">
                <div className="relative">
                  {/* delete image */}
                  <div
                    className="absolute -top-2 -right-2 cursor-pointer"
                    onClick={() => {
                      deleteData(
                        { dispatch, redux: dataReducer },
                        "",
                        API_URL_deleteimage,
                        "DELETE_DATA"
                      );
                    }}
                  >
                    <HiOutlineXCircle className="text-red-500 cursor-pointer text-xl" />
                  </div>
                  <img
                    src={image}
                    alt="image"
                    className="w-40 h-auto rounded-lg border border-primary-700 p-2"
                  />
                </div>
              </div>
            )}
            <div className="w-full flex flex-col">
              <div className="w-full">{warding}</div>
            </div>
          </div>

          <div>
            <div className="flex justify-end gap-2">
              <Button
                color="success"
                onClick={onBroadcast}
                type={"button"}
                loading={addBroadcastLoading}
              >
                Broadcast
              </Button>
              <Button
                onClick={() => {
                  setModalWarding(true);
                  formik.setFieldValue("image", []);
                  formik.setFieldValue("warding", warding);
                }}
                type={"button"}
              >
                Edit
              </Button>
            </div>
          </div>
        </div>

        <HeadContent
          placeholderSearch={"Cari"}
          onSearch={onSearch}
          limit={limit}
          pageCount={getDataResult.count > 0 ? getDataResult.count : 0}
          setLimit={handleSelect}
          onAdd={() => {
            setModalKontak(true);
            formikKontak.resetForm();
          }}
          onAddText={"Tambah Kontak"}
          onImport={handleImport}
          onDelete={onDeleteAll}
        />
        <div className="flex flex-col">
          <div className="overflow-y-auto">
            <table className="w-full text-xs sm:text-sm">
              <thead>
                <tr className="text-left text-base-500 text-white border-b-4">
                  <th className="p-2 bg-primary rounded-l-lg">No</th>
                  <th className="p-2 bg-primary">Nama</th>
                  <th className="p-2 bg-primary">No Hp</th>
                  <th className="p-2 bg-primary">Perusahaan</th>
                  <th className="p-2 bg-primary">Panggilan</th>
                  <th className="p-2 bg-primary text-center rounded-r-lg">
                    Aksi
                  </th>
                </tr>
              </thead>
              <tbody>
                {/* Loading */}
                {getDataLoading ? (
                  <tr className="text-base-800 border-b-4 bg-white">
                    <td className="p-10" colSpan="7">
                      <div className="flex justify-center items-center">
                        <ReactLoading type={"bars"} color={"#002fa8"} />
                      </div>
                    </td>
                  </tr>
                ) : // No Data
                getDataResult.count === 0 ? (
                  <tr className="text-base-800 border-b-4 bg-white">
                    <td className="p-10" colSpan="7">
                      <div className="flex flex-col justify-center items-center">
                        <ErrorMessages
                          messages="Data tidak ditemukan"
                          type="nodata"
                        />
                      </div>
                    </td>
                  </tr>
                ) : // Data
                getDataResult.count > 0 ? (
                  getDataResult.results.map((item, itemIdx) => (
                    <tr
                      className={`text-base-800 font-semibold border-b-4 odd:bg-white even:bg-[#F3F6FF]`}
                      key={itemIdx}
                    >
                      <td className="p-2">
                        {pageActive * limit + itemIdx + 1}
                      </td>
                      <td className="p-2">{item.nama}</td>
                      <td className="p-2">{item.nohp}</td>
                      <td className="p-2">{item.perusahaan}</td>
                      <td className="p-2">{item.panggilan}</td>
                      <td className="p-2">
                        <div className="flex justify-center gap-x-2 text-base">
                          <ButtonHover textHover="Edit">
                            <FiEdit
                              onClick={() => onEdit(item)}
                              className="cursor-pointer text-blue-500"
                            />
                          </ButtonHover>

                          <ButtonHover placement="right" textHover="Delete">
                            <RiDeleteBin6Line
                              onClick={() => onDelete(item)}
                              className="cursor-pointer text-red-500"
                            />
                          </ButtonHover>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : // Error
                getDataError ? (
                  <tr className="text-base-800 border-b-4 bg-white">
                    <td className="p-10" colSpan="9">
                      <div className="flex flex-col justify-center items-center">
                        <ErrorMessages messages={getDataError} type="error" />
                      </div>
                    </td>
                  </tr>
                ) : null}
              </tbody>
            </table>
          </div>
          <Pagination
            handlePageClick={handlePageClick}
            pageActive={pageActive}
            pageCount={getDataResult.count > 0 ? getDataResult.count : 0}
            limit={limit}
          />
        </div>
      </div>

      <Modal
        dismiss
        show={modalWarding}
        setShow={setModalWarding}
        title={"Form Warding"}
        sizeModal={"md"}
      >
        {/* Content */}
        <div className="p-5">
          <form
            onSubmit={formik.handleSubmit}
            className="flex flex-col gap-y-4"
          >
            <div className="mb-2">
              <Label htmlFor={`image`}>Image</Label>
              <Input
                id={`image`}
                name={`image`}
                type="image"
                onChange={(e) => {
                  const files = e.target.files[0];
                  files["preview"] = URL.createObjectURL(e.target.files[0]);
                  formik.setFieldValue("image", [files]);
                }}
                formik={formik}
                preview
              />
            </div>

            <div className="mb-2">
              <Label htmlFor={`warding`} required>
                Warding Whatsapp
              </Label>
              <Input
                id={`warding`}
                name={`warding`}
                placeholder={"Masukkan warding whatsapp"}
                type="textarea"
                onChange={formik.handleChange}
                formik={formik}
                rows={10}
              />
            </div>
          </form>
        </div>

        {/* Footer */}
        <div className="space-x-2 h-full flex justify-end items-center">
          <Button
            color={"secondary"}
            type={"button"}
            onClick={() => setModalWarding(false)}
          >
            Cancel
          </Button>
          <Button
            type={"submit"}
            loading={addDataLoading}
            onClick={formik.handleSubmit}
          >
            Simpan
          </Button>
        </div>
      </Modal>

      <Modal
        dismiss
        show={modalKontak}
        setShow={setModalKontak}
        title={"Form Kontak"}
        sizeModal={"md"}
      >
        {/* Content */}
        <div className="p-5">
          <form
            onSubmit={formik.handleSubmit}
            className="flex flex-col gap-y-2"
          >
            <div>
              <Label htmlFor={`nama`} required>
                Nama Direktur/Owner
              </Label>
              <Input
                id={`nama`}
                name={`nama`}
                placeholder={"Masukkan nama direktur/owner"}
                type="text"
                onChange={formikKontak.handleChange}
                formik={formikKontak}
              />
            </div>

            <div>
              <Label htmlFor={`nohp`} required>
                Nomor Handphone Direktur/Owner
              </Label>
              <Input
                id={`nohp`}
                name={`nohp`}
                placeholder={"Masukkan nomor handphone direktur/owner"}
                type="text"
                onChange={formikKontak.handleChange}
                formik={formikKontak}
              />
            </div>

            <div>
              <Label htmlFor={`perusahaan`} required>
                Nama Perusahaan
              </Label>
              <Input
                id={`perusahaan`}
                name={`perusahaan`}
                placeholder={"Masukkan perusahaan"}
                type="text"
                onChange={formikKontak.handleChange}
                formik={formikKontak}
              />
            </div>

            <div>
              <Label htmlFor={`panggilan`} required>
                Panggilan
              </Label>
              <Input
                id={`panggilan`}
                name={`panggilan`}
                placeholder={"Masukkan ( Bro / Pak / Mbak )"}
                type="text"
                onChange={formikKontak.handleChange}
                formik={formikKontak}
              />
            </div>
          </form>
        </div>

        {/* Footer */}
        <div className="space-x-2 h-full flex justify-end items-center">
          <Button
            color={"secondary"}
            type={"button"}
            onClick={() => setModalKontak(false)}
          >
            Cancel
          </Button>
          <Button
            type={"submit"}
            loading={addDataLoading}
            onClick={formikKontak.handleSubmit}
          >
            Simpan
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default FirstPage;
