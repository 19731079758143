import { Transition } from "@headlessui/react";
import { FiX } from "react-icons/fi";

const Modal = ({
  show,
  setShow,
  dismiss,
  children,
  title,
  sizeModal,
  fixed,
  closeButton,
  closeFunction,
  formik,
}) => {
  let size = "sm:w-5/6";
  switch (sizeModal) {
    case "sm":
      size = "sm:w-1/2";
      break;
    case "md":
      size = "sm:w-2/3";
      break;
    case "lg":
      size = "sm:w-5/6";
      break;
    case "full":
      size = "sm:w-full";
      break;
    default:
      size = "sm:w-5/6";
      break;
  }

  const onDismiss = () => {
    if (dismiss) {
      setShow(false);
    }
  };

  return (
    <Transition
      show={show}
      className="fixed inset-0 z-50 h-screen w-full p-5 flex flex-col items-center justify-center overflow-auto"
    >
      {/* Backdrop */}
      <Transition.Child
        as="div"
        onClick={onDismiss}
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        className="w-screen h-screen absolute bg-black bg-opacity-50 transition-opacity duration-500"
      />

      <Transition.Child
        as="div"
        enterFrom="opacity-0 -translate-y-6"
        enterTo="opacity-100 translate-y-0"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 -translate-y-6"
        className="w-full h-full flex flex-col items-center justify-center transition-all duration-700 pointer-events-none"
      >
        {/* Head */}
        <div
          className={`relative w-full h-16 p-5 flex justify-between bg-white dark:bg-neutral-800 dar rounded-t border-b pointer-events-auto ${
            size ? size : ""
          }`}
        >
          {/* title */}
          {title && (
            <div className="flex items-center text-2xl font-bold">{title}</div>
          )}

          {/* close */}
          {closeButton && (
            <button
              onClick={closeFunction ? closeFunction : () => setShow(false)}
              className="cursor-pointer"
            >
              <FiX size={24} />
            </button>
          )}
        </div>

        {/* content */}
        <div
          className={`relative flex-1 w-full h-full scrollbar-hide bg-white dark:bg-neutral-800 shadow-lg pointer-events-auto ${
            size ? size : ""
          } ${fixed ? "" : "overflow-y-auto"}`}
        >
          {formik ? (
            <form className="relative w-full" onSubmit={formik.handleSubmit}>
              {Array.isArray(children) ? children[0] : children}
              <button type="submit" />
            </form>
          ) : Array.isArray(children) ? (
            children[0]
          ) : (
            children
          )}
        </div>

        {Array.isArray(children) && (
          <div
            className={`relative w-full flex items-center justify-end h-20 px-5 bg-white dark:bg-neutral-800 rounded-b border-t pointer-events-auto ${
              size ? size : ""
            }`}
          >
            <div className="h-full w-full">
              {Array.isArray(children) ? children[1] : null}
            </div>
          </div>
        )}
      </Transition.Child>
    </Transition>
  );
};

export default Modal;
