import React from "react";

const Label = ({ children, htmlFor, required = false }) => {
  return (
    <label htmlFor={htmlFor} className="font-semibold">
      {`${children} `}
      {required && <span className="text-red-500">*</span>}
    </label>
  );
};

export default Label;
