import React from "react";
import { BsSendFill } from "react-icons/bs";
import { FaFileExcel } from "react-icons/fa";
import { FiFilter, FiSearch, FiTrash } from "react-icons/fi";
import { HiOutlinePlusCircle } from "react-icons/hi";

const HeadContent = ({
  title,
  onSearch,
  placeholderSearch,
  onAdd,
  onAddText,
  onDelete,
  limit,
  setLimit,
  pageCount,
  onImport,
  onBroadcast,
}) => {
  return (
    <div className="block mb-4 items-center justify-between">
      <div className="mb-2 md:mb-0 mr-2">
        <h1 className="text-2xl font-bold">{title}</h1>
      </div>

      <div className="sm:flex justify-between items-center w-full mt-3 bg-white py-5 px-3 rounded-md shadow-lg">
        <div className="flex items-center">
          {limit && (
            <>
              <div className="text-xs mr-2">Show</div>
              <select
                value={limit}
                className="flex bg-white dark:bg-neutral-800 text-xs border border-base-300 dark:border-neutral-700 rounded-lg h-7 w-12 outline-none pl-1"
                onChange={(e) => setLimit(parseInt(e.target.value))}
              >
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select>
              <div className="ml-2 text-xs">{pageCount} entries</div>
            </>
          )}
        </div>

        <div className="flex items-end gap-x-2">
          {onSearch && (
            <label className="bg-white dark:bg-neutral-800 w-60 h-10 p-2 outline-none rounded-lg shadow flex">
              <div className="p-1 mr-2">
                <FiSearch />
              </div>
              <input
                type="text"
                className="outline-none w-full text-xs dark:bg-neutral-800"
                placeholder={placeholderSearch}
                onChange={onSearch}
              />
            </label>
          )}

          {/* Import */}
          {onImport && (
            <button className="relative hover:cursor-pointer flex justify-center items-center h-10 w-fit px-2 bg-gradient-to-r from-green-900 to-green-600 hover:from-green-800 hover:to-green-500 text-white text-sm font-semibold rounded-lg shadow transition-all gap-x-2">
              <FaFileExcel /> Import
              <input
                onChange={onImport}
                className="absolute h-full w-full opacity-0 hover:cursor-pointer"
                type="file"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              />
            </button>
          )}

          {/* Broadcast */}
          {onBroadcast && (
            <button
              onClick={onBroadcast}
              className="relative hover:cursor-pointer flex justify-center items-center h-10 w-fit px-2 bg-gradient-to-r from-green-900 to-green-600 hover:from-green-800 hover:to-green-500 text-white text-sm font-semibold rounded-lg shadow transition-all gap-x-2"
            >
              <BsSendFill /> Broadcast
            </button>
          )}

          {/* Add */}
          {onAdd && (
            <button
              onClick={onAdd}
              className="flex justify-center items-center h-10 w-fit px-2 bg-gradient-to-r from-primary-900 to-primary-500 hover:from-primary-800 hover:to-primary-400 text-white text-sm font-semibold rounded-lg shadow transition-all gap-x-2"
            >
              <HiOutlinePlusCircle />
              {onAddText}
            </button>
          )}

          {/* Delete */}
          {onDelete && (
            <button
              onClick={onDelete}
              className="flex justify-center items-center h-10 w-fit px-2 bg-gradient-to-r from-red-700 to-red-500 hover:from-red-600 hover:to-red-400 text-white text-sm font-semibold rounded-lg shadow transition-all gap-x-2"
            >
              <FiTrash /> Delete
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default HeadContent;
